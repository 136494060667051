<template>
  <div class="col-md-12 mt-3 row">
    <div class="col-md-12 mt-2">
      <label class="label_default label_sm">
        *Unidade sem valor, mantem o valor padrão*
      </label>
    </div>
    <div class="col-md-7 mt-2">
      <label class="label_default label_blue">
        Comissoes por Unidade
      </label>
    </div>
    <div class="col-md-5 mr-auto mb-1">
      <div class="row align-items-center">
        <div class="col-md-12 mb-2">
          <div class="d-flex align-items-center">
            <inputFilter @filterCp="filterCp" id="searchTenantValue" />
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12 table-responsive-sm">
      <table class="table table-sm table-striped table-hover table_default">
        <thead>
          <tr>
            <th scope="col">Unidade</th>
            <th scope="col-2" class="text-center">Comissao Master %</th>
            <th scope="col-2" class="text-center">Comissao Franqueado %</th>
            <!-- <th scope="col-2" class="text-center">Comissao Vendedor</th> -->
            <th scope="col-2" class="text-center"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(tenant, index) in resultQuery" :key="index">
            <td>{{ tenant.tenanancyName }}</td>
            <td class="text-center">
              {{ getValue(tenant.comissionMaster) }}
            </td>
            <td class="text-center">
              {{ getValue(tenant.comissionFranchisee) }}
            </td>
            <!-- <td class="text-center">
              {{ getValue(tenant.comissionSallesman) }}
            </td> -->
            <td class="d-flex justify-content-around">
              <button
                class="btn btn-outline-light btn-sm"
                type="button"
                @click="
                  $emit('openModal', 'modal', {
                    id: tenant.id,
                    tenantId: tenant.tenantId,
                    tenanancyName: tenant.tenanancyName,
                    tenants: [],
                    comissionSallesman: getValue(tenant.comissionSallesman),
                    comissionMaster: getValue(tenant.comissionMaster),
                    comissionFranchisee: getValue(tenant.comissionFranchisee)
                  })
                "
              >
                <img src="@/assets/image/icones/editPen.svg" />
              </button>

              <button
                class="btn btn-outline-danger btn-sm"
                type="button"
                @click="
                  $emit('openModal', 'modalDelete', {
                    tenanancyName: tenant.tenanancyName
                  })
                "
              >
                <i class="fas fa-trash-alt"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <hr class="w-100" />
  </div>
</template>

<script>
export default {
  props: ["listValues"],
  data() {
    return {
      filter: ""
    };
  },
  components: {
    inputFilter: () => import("@/components/filters/inputFilter.vue")
  },
  methods: {
    filterCp(event) {
      this.filter = `${event.target.value}`;
    },
    getValue(val) {
      return val == null || val == "" ? "" : parseFloat(val).toFixed(2);
    }
  },
  computed: {
    resultQuery() {
      if (this.filter) {
        return this.listValues.filter(tenant => {
          return this.filter
            .toLowerCase()
            .split(" ")
            .every(v => tenant.tenanancyName.toLowerCase().includes(v));
        });
      } else {
        return this.listValues;
      }
    }
  }
};
</script>

<style scoped src="@/assets/style/modal.css"></style>
<style scoped>
.btn-outline-light {
  height: 27px;
  border: none;
  border-radius: 6px !important;
  width: 30px !important;
  margin-top: -3px;
}

.btn-outline-danger {
  height: 27px;
  border: none;
  border-radius: 6px !important;
  width: 30px !important;
  margin-top: -3px;
}
.col-md-12.table-responsive-sm {
  max-height: 270px;
  overflow: auto;
}
</style>
